/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const HeadingContainer = styled.div`
  height: 400px;
  width: 100%;
  background-image: url('${({ backImage }) => backImage?.image?.file?.url || ''}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .job-listing-heading--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;

    h2 {
      font-weight: var(--font-weight-900);
      color: var(--white);
      text-align: center;
    }
  }

  @media (max-width: 767px) {
    height: 220px;

    .job-listing-heading--wrapper {
      h2 {
        font-size: 29px;
      }
    }
  }
`;
