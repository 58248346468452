/* eslint-disable import/prefer-default-export */
import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import { HeadingContainer } from './styles';

export const JobListingHeading = ({ backImage, headingCopy }) => {
  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => {
        return <h2>{children}</h2>;
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2>{children}</h2>;
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h2>{children}</h2>;
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <>{children}</>;
      },
    },
  };

  return (
    <HeadingContainer className="job-listing-heading--container" backImage={backImage}>
      <div className="job-listing-heading--wrapper">
        {headingCopy?.content?.raw && renderRichText(headingCopy?.content, options)}
      </div>
    </HeadingContainer>
  );
};
