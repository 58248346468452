import React from 'react';
import { getEntry } from 'components/Contentful/ContentfulLayout/utils/entriesHelpers';
import { JobListingHeading } from './Heading';
import { JobListingPosts } from './Posts';

const CareersJobListingSection = ({ entries }) => {
  const heading = {
    backImage: getEntry('headingBack', entries),
    headingCopy: getEntry('headingCopy', entries),
  };

  return (
    <div className="careers-job-listing--container">
      <div className="careers-job-listing--wrapper">
        <JobListingHeading {...heading} />
        <JobListingPosts />
      </div>
    </div>
  );
};

export default CareersJobListingSection;
